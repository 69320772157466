import request from "@/utils/request";

export const getList = (page,size,params)=>{
    return request({
        url:'/api/fusion/setting-group/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/fusion/setting-group/detail',
        method:'get',
        params:{id}
    })
}

export const submit = (data)=>{
    return request({
        url:'/api/fusion/setting-group/submit',
        method:'post',
        data:data
    })
}

export const remove = (id)=>{
    return request({
        url:'/api/fusion/setting-group/remove',
        method:'get',
        params:{id}
    })
}
